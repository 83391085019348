/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IsEqual, Simplify } from 'type-fest';

import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { GqlClient } from '@change-corgi/core/gql';
import type { EmptyIntersection } from '@change-corgi/core/types';

import { FcmMergeError, InvalidFcmError } from './error';
import type { Normalizer } from './normalizers';

type FeatureConfigVariable = 'locale' | 'countryCode';

type MergeValuePrefix = 'global' | 'locale' | 'country';

type MergeValues<T = any> = Record<`${MergeValuePrefix}Value`, T>;

type Merger<T = any> = (values: MergeValues<T>) => T;

type SimplifyWhenEqual<T, D> = IsEqual<T, D> extends true ? T : T | D;
type SimplifyWhenObject<T> = T extends EmptyIntersection ? Simplify<T> : T;

export type FeatureConfigInfo<T = any, D = any> = Readonly<{
	name: string;
	/**
	 * normalizer function that:
	 * - returns a normalized value
	 * - throws an error if the value is in an unsupported format => the FCM value will then be defaulted to "defaultValue"
	 */
	normalizer: Normalizer<T>;
	defaultValue: D;
	/**
	 * if defined, will be used client-side to merge global/locale/country values into a single value
	 */
	merger?: Merger<SimplifyWhenEqual<T, D>>;
}>;

export type FeatureConfigData<T extends Record<string, FeatureConfigInfo> = Record<string, FeatureConfigInfo>> = {
	[K in keyof T]: SimplifyWhenObject<SimplifyWhenEqual<ReturnType<T[K]['normalizer']>, T[K]['defaultValue']>>;
};

export type Options = Readonly<{
	reportError?: ErrorReporter['report'];
	gqlFetch: GqlClient['fetch'];
	locale: string;
	countryCode: string;
	/**
	 * Throws the error when the request fails
	 * Default behavior is to fall back to the default values
	 */
	throwOnFetchError?: boolean;
}>;

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface FcmUtils {
	get<T extends Record<string, FeatureConfigInfo>>(query: T): Promise<FeatureConfigData<T>>;
	normalizeFcmValue<T = unknown, D = unknown>(configInfo: FeatureConfigInfo<T, D>, value: unknown): T | D;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class FcmUtilsImpl implements FcmUtils {
	private readonly locale: Options['locale'];
	private readonly countryCode: Options['countryCode'];
	private readonly gqlFetch: Options['gqlFetch'];
	private readonly reportError: Options['reportError'];
	private readonly throwOnFetchError: Options['throwOnFetchError'];

	constructor({ gqlFetch, locale, countryCode, reportError, throwOnFetchError }: Options) {
		this.reportError = reportError;
		this.gqlFetch = gqlFetch;
		this.locale = locale;
		this.countryCode = countryCode;
		this.throwOnFetchError = throwOnFetchError;
	}

	private mergeValues(configInfo: FeatureConfigInfo, mergeValues: MergeValues) {
		try {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return configInfo.merger!(mergeValues);
		} catch (e) {
			// cannot normalize => use default value instead

			const reason = (e as Error).message || undefined;

			// eslint-disable-next-line no-console
			console.warn(`[FCM] Failed to merge values for ${configInfo.name}`, { reason });

			void this.reportError?.({
				error: new FcmMergeError({ fcmName: configInfo.name, reason }),
				params: { fcmName: configInfo.name, mergeValues, reason },
			});

			return configInfo.defaultValue;
		}
	}

	private normalizeResult<T extends Record<string, FeatureConfigInfo>>(
		query: T,
		configs: Record<string, unknown> | undefined,
	): FeatureConfigData<T> {
		return Object.keys(query).reduce<Partial<FeatureConfigData<T>>>((acc, resVariableName) => {
			const { merger } = query[resVariableName];
			if (merger) {
				return {
					...acc,
					[resVariableName]: this.mergeValues(query[resVariableName], {
						globalValue: normalizeFcmValue(
							query[resVariableName],
							configs ? configs[`${resVariableName}__${'global' satisfies MergeValuePrefix}`] : null,
							this.reportError,
						),
						countryValue: normalizeFcmValue(
							query[resVariableName],
							configs ? configs[`${resVariableName}__${'country' satisfies MergeValuePrefix}`] : null,
							this.reportError,
						),
						localeValue: normalizeFcmValue(
							query[resVariableName],
							configs ? configs[`${resVariableName}__${'locale' satisfies MergeValuePrefix}`] : null,
							this.reportError,
						),
					}),
				};
			}
			return {
				...acc,
				[resVariableName]: normalizeFcmValue(
					query[resVariableName],
					configs ? configs[resVariableName] : null,
					this.reportError,
				),
			};
		}, {}) as FeatureConfigData<T>;
	}

	/**
	 * @example
	 * fcmUtils.get(
	 *  {
	 *    showSapCtaForLocale: { name: 'sap_cta_on_petition_show', normalizer: normalizeBoolean, defaultValue: false },
	 *    copyLinkOnUnsigned: { name: 'copy_link_on_unsigned', normalizer: normalizeString, defaultValue: '' },
	 *  },
	 * );
	 */
	async get<T extends Record<string, FeatureConfigInfo>>(query: T): Promise<FeatureConfigData<T>> {
		const { locale, countryCode } = this;

		// generate list of GQL FCM queries
		const fns = Object.keys(query).map((resVariableName: keyof T) => {
			const configInfo = query[resVariableName] as FeatureConfigInfo;
			if (configInfo.merger) {
				return [
					`${resVariableName as string}__${'global' satisfies MergeValuePrefix}: featureConfig(name: "${
						configInfo.name
					}", groups: ["all"], noGlobalFallback: true)`,
					`${resVariableName as string}__${'locale' satisfies MergeValuePrefix}: featureConfig(name: "${
						configInfo.name
					}", groups: [$locale], noGlobalFallback: true)`,
					`${resVariableName as string}__${'country' satisfies MergeValuePrefix}: featureConfig(name: "${
						configInfo.name
					}", groups: [$countryCode], noGlobalFallback: true)`,
				].join('\n\t\t');
			}
			return `${resVariableName as string}: featureConfig(name: "${configInfo.name}", groups: [$locale, $countryCode])`;
		});

		if (!fns.length) {
			return {} as FeatureConfigData<T>;
		}

		try {
			const { data } = await this.gqlFetch<
				{ featureConfigs: Record<string, unknown> },
				Record<FeatureConfigVariable, string>
			>({
				query: `query FeCoreFeatureConfigs($locale: String!, $countryCode: String!) {
	featureConfigs {
		${fns.join('\n\t\t')}
	}
}`,
				variables: { locale, countryCode },
			});

			const configs = data?.featureConfigs;

			return this.normalizeResult<T>(query, configs);
		} catch (e) {
			if (this.throwOnFetchError) {
				throw e;
			}
			// falls back on default values if error occurs
			return Object.keys(query).reduce<Partial<FeatureConfigData<T>>>(
				(acc, resVariableName) => ({ ...acc, [resVariableName]: query[resVariableName].defaultValue }),
				{},
			) as FeatureConfigData<T>;
		}
	}

	/**
	 * utility function for cases when get() cannot be used (e.g. in fe)
	 */
	normalizeFcmValue<T = unknown, D = unknown>(configInfo: FeatureConfigInfo<T, D>, value: unknown): T | D {
		return normalizeFcmValue<T, D>(configInfo, value, this.reportError);
	}
}

/**
 *
 * @param configInfo
 * @param value the value from the FCM service (should be null if not found)
 * @param reportError
 * @returns
 */
export function normalizeFcmValue<T = unknown, D = unknown>(
	configInfo: FeatureConfigInfo<T, D>,
	value: unknown,
	reportError?: ErrorReporter['report'],
): T | D {
	if (value === null) {
		// FCM not found => use default value instead
		return configInfo.defaultValue;
	}
	try {
		return configInfo.normalizer(value);
	} catch (e) {
		// cannot normalize => use default value instead

		const reason = (e as Error).message || undefined;

		// eslint-disable-next-line no-console
		console.warn(`[FCM] Invalid value for ${configInfo.name}`, { value, reason });

		void reportError?.({
			error: new InvalidFcmError({ fcmName: configInfo.name, reason }),
			params: { fcmValue: value, fcmName: configInfo.name, reason },
		});

		return configInfo.defaultValue;
	}
}

export type { FcmUtils };

export function createFcmUtils(options: Options): FcmUtils {
	return new FcmUtilsImpl(options);
}

export function createFcmUtilsFake(errorMsg: string): FcmUtils {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		get: errorFn,
		normalizeFcmValue: errorFn,
	};
}
