import { memo as _memo } from 'react';
import type { ComponentType, FunctionComponent, PropsWithRef } from 'react';

// function component version of memo for easier mocking
export function memo<P>(comp: ComponentType<P>): FunctionComponent<PropsWithRef<P>> {
	const ReactMemoComponent = _memo(comp);

	return function MemoComponent(props) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return <ReactMemoComponent {...(props as any)} />;
	};
}
