import type { GraphQLRequest } from '@apollo/client';
import uniq from 'lodash/uniq';
import qs from 'qs';

import type { ReportableError, ReportOptions } from '@change-corgi/core/errorReporter/common';

import { getRequestGqlDetails } from './getRequestGqlDetails';
import { getRequestMethod } from './getRequestMethod';
import { getRequestUrl } from './getRequestUrl';
import { reportResponseError } from './reportResponseError';

type Options = {
	fetch: typeof fetch;
	reportError: (error: ReportableError, options?: ReportOptions) => void;
	reportNetworkError: (error: ReportableError) => void;
	addOperationNamesToUri?: boolean;
};

function getOperationNames(bodyJson: BodyInit | undefined | null): readonly string[] {
	if (typeof bodyJson !== 'string') return [];
	try {
		const body = JSON.parse(bodyJson) as GraphQLRequest | GraphQLRequest[];
		const bodyArray = Array.isArray(body) ? body : [body];
		return bodyArray
			.map(({ operationName }) => operationName)
			.filter((operationName): operationName is string => !!operationName);
	} catch (e) {
		return [];
	}
}

function buildUrl(uri: RequestInfo | URL, operationNames: readonly string[] | undefined) {
	if (typeof uri !== 'string' || !operationNames || !operationNames.length) return uri;
	const [url, query] = uri.split('?');
	return `${url}?${qs.stringify(
		{
			...qs.parse(query),
			op: uniq(operationNames)
				.sort()
				.map((operationName) => {
					const count = operationNames.filter((name) => name === operationName).length;
					return count > 1 ? `${operationName}~${count}` : operationName;
				}),
		},
		{ arrayFormat: 'repeat' },
	)}`;
}

export function wrapFetch({
	fetch: originalFetch,
	reportError,
	reportNetworkError,
	addOperationNamesToUri,
}: Options): typeof fetch {
	// Patch fetch to ensure error includes relevant debugging info

	return async function fetch(infoParam, init) {
		/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument */
		const info = addOperationNamesToUri ? buildUrl(infoParam as any, getOperationNames(init?.body)) : infoParam;
		try {
			const result = await originalFetch(info as any, init);
			if (!result.ok) {
				// eslint-disable-next-line @typescript-eslint/only-throw-error
				throw result;
			}
			return result;
		} catch (e) {
			if ((e as Response).status) {
				const response = e as Response;

				reportResponseError(init, info as any, response, reportError);

				if (response.status < 500) {
					return response;
				}
				throw e;
			}
			void reportNetworkError({
				error: e as Error,
				params: {
					url: getRequestUrl(info as any),
					method: getRequestMethod(info as any, init),
					gqlDetails: getRequestGqlDetails(init),
				},
			});
			throw e;
		}
		/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument */
	};
}
