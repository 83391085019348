import type { ApolloLink, FetchResult, ServerError } from '@apollo/client';
import { execute, from, throwServerError } from '@apollo/client';
import omit from 'lodash/fp/omit';

import { trimStack, updateStack } from '@change-corgi/core/error';
import type { ReportableError, ReportOptions } from '@change-corgi/core/errorReporter/common';
import { isPerimeterXEnforcerChallenge } from '@change-corgi/core/perimeterx';

import { errorHandlerLink } from './errorHandlerLink';
import { wrapFetch } from './fetch';
import type { HttpLinkOptions, HttpLinks } from './getHttpLinks';
import { getHttpLinks } from './getHttpLinks';
import { makePromise } from './makePromise';
import { operationIdLink } from './operationIdLink';
import { parseQuery } from './parser';
import { responseInfoLink } from './responseInfoLink';
import type { GQLFetchRequest } from './types';
import { GQLResponseError, GQLResponsePerimeterXChallengeError, GQLResponseServerError } from './types';
import type { WebappInfo } from './webappInfoLink';
import { webappInfoLink } from './webappInfoLink';

export type Options = HttpLinkOptions & {
	reportError: (error: ReportableError, options?: ReportOptions) => void;
	reportNetworkError: (error: ReportableError) => void;
	addOperationNamesToUri?: boolean;
	webappInfo?: WebappInfo;
};

type DecoratedFetchResult<
	// eslint-disable-next-line @typescript-eslint/naming-convention
	TData = Record<string, unknown>,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	TContext = Record<string, unknown>,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	TExtensions = Record<string, unknown>,
> = FetchResult<TData, TContext, TExtensions> & {
	/**
	 * The requestId coming from the response headers. Can be useful for debugging.
	 */
	requestId?: string;
};

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface GqlClient {
	/**
	 * @throws {GQLResponseError} if `rejectOnError` is `true` and there are errors in the response body
	 * @throws {GQLResponseServerError} if the response is a server error
	 * @throws {GQLResponsePerimeterXChallengeError} if the response is a PerimeterX challenge
	 */
	fetch<
		DATA extends Record<string, unknown> = Record<string, unknown>,
		VARS extends Readonly<Record<string, unknown>> = Readonly<Record<string, unknown>>,
	>(
		operation: GQLFetchRequest<VARS> & {
			/**
			 * if there are errors in the response body, rejects with a `GQLResponseError` instead of resolving
			 */
			rejectOnError: true;
		},
	): Promise<Omit<DecoratedFetchResult<DATA>, 'errors'>>;
	/**
	 * @throws {GQLResponseError} if `rejectOnError` is `true` and there are errors in the response body
	 * @throws {GQLResponseServerError} if the response is a server error
	 * @throws {GQLResponsePerimeterXChallengeError} if the response is a PerimeterX challenge
	 */
	fetch<
		DATA extends Record<string, unknown> = Record<string, unknown>,
		VARS extends Readonly<Record<string, unknown>> = Readonly<Record<string, unknown>>,
	>(
		operation: GQLFetchRequest<VARS> & {
			/**
			 * if there are errors in the response body, rejects with a `GQLResponseError` instead of resolving
			 */
			rejectOnError?: false;
		},
	): Promise<DecoratedFetchResult<DATA>>;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class GqlClientImpl implements GqlClient {
	private readonly options: Options;
	private readonly links: HttpLinks;
	private readonly _fetch: typeof fetch;
	private readonly webappInfoLink: ApolloLink;
	private readonly errorHandlerLink: ApolloLink;

	constructor(options: Options) {
		const { reportError, reportNetworkError, uri, headers, fetch: fetchOverride, addOperationNamesToUri } = options;
		this._fetch = wrapFetch({ fetch: fetchOverride || fetch, reportError, reportNetworkError, addOperationNamesToUri });
		this.options = options;
		this.links = getHttpLinks({ uri, headers, fetch: this._fetch });
		this.errorHandlerLink = errorHandlerLink(reportError);
		this.webappInfoLink = webappInfoLink(options.webappInfo);
	}

	private getUri(path: string | undefined) {
		const { uri } = this.options;
		return path ? `${uri}${path}` : uri;
	}

	// eslint-disable-next-line max-lines-per-function
	async fetch<
		DATA extends Record<string, unknown> = Record<string, unknown>,
		VARS extends Readonly<Record<string, unknown>> = Readonly<Record<string, unknown>>,
	>(operation: GQLFetchRequest<VARS> & { rejectOnError?: boolean }): Promise<DecoratedFetchResult<DATA>> {
		// this is used to get the stacktrace of the caller instead of ending up
		// with an unhelpful stacktrace pointing to "fetch" in some browsers
		const { stack } = new Error();
		const stackTrim = 1;

		if (operation.path && !operation.path.startsWith('/')) {
			throw new Error('[gql.fetch] custom path must start with a slash');
		}

		const query = parseQuery(operation.query);
		const promise = makePromise(
			execute(
				/**
				 * operationIdLink => errorHandlerLink => responseInfoLink => httpLink
				 *                     (does nothing)      (does nothing)         |
				 *                                                                v
				 * operationIdLink <= errorHandlerLink <= responseInfoLink <= httpLink
				 *                  (needs response info) (adds response info
				 *                                           to extensions)
				 */
				from([
					this.webappInfoLink,
					operationIdLink,
					this.errorHandlerLink,
					responseInfoLink,
					operation.important ? this.links.single : this.links.batch,
				]),
				{
					...omit(['important', 'path', 'rejectOnError'], operation),
					context: {
						...operation.context,
						// this will override the uri set in getHttpLinks()
						uri: this.getUri(operation.path),
						stack,
						stackTrim,
					},
					query,
				},
			),
		) as Promise<FetchResult<DATA>>;

		try {
			const result = await promise;
			const requestId = result.extensions?.requestId as string | undefined;
			if (result.errors && operation.rejectOnError) {
				// remove first line of stacktrace which would be the current function
				throw trimStack(
					updateStack(
						new GQLResponseError({
							errors: result.errors,
							requestId,
						}),
						stack,
					),
					stackTrim,
				);
			}
			return { ...result, requestId };
		} catch (err) {
			throw trimStack(updateStack(serverErrorToGqlResponseError(err), stack), stackTrim);
		}
	}
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any */
function serverErrorToGqlResponseError(err: any) {
	if (err.name !== 'ServerError' && !(err instanceof Error) && err.status) {
		const response = err as Response;
		// this appears to be a Response object that was not transformed into a ServerError
		try {
			throwServerError(response, response.body, response.statusText);
		} catch (serverError) {
			// eslint-disable-next-line no-param-reassign
			err = serverError;
		}
	}
	if (err.name === 'ServerError') {
		const serverError = err as ServerError;
		if (serverError.statusCode === 403 && isPerimeterXEnforcerChallenge(serverError.result)) {
			return new GQLResponsePerimeterXChallengeError({ serverError, pxChallenge: serverError.result });
		}
		const errors = (() => {
			if (!Array.isArray(err.result)) {
				return err.result.errors || [];
			}
			return [].concat(...err.result.filter((result: any) => !!result.errors).map((result: any) => result.errors));
		})();
		return new GQLResponseServerError({
			errors,
			serverError,
			requestId: serverError.response.headers.get('x-request-id') || undefined,
		});
	}
	return err;
}
/* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any */

export type { GqlClient };

export function createGqlClient(options: Options): GqlClient {
	return new GqlClientImpl(options);
}

export function createGqlClientFake(errorMsg: string): GqlClient {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		fetch: errorFn,
	};
}
