function addFlatObject(acc: Record<string, string>, object: unknown, prefix?: string): void {
	if (!object || typeof object !== 'object') {
		return;
	}
	Object.entries(object).forEach(([key, value]) => {
		if (typeof value === 'object') {
			addFlatObject(acc, value, prefix ? `${prefix}.${key}` : key);
			const valueObj = value as object;
			if (valueObj.toString && valueObj.toString !== Object.prototype.toString) {
				// eslint-disable-next-line no-param-reassign, @typescript-eslint/no-base-to-string
				acc[prefix ? `${prefix}.${key}` : key] = valueObj.toString();
			}
			return;
		}
		if (typeof value === 'function') {
			return;
		}
		// eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-assignment
		acc[prefix ? `${prefix}.${key}` : key] = value;
	}, {});
}

export function toFlatObject(object: unknown, prefix?: string): Readonly<Record<string, string>> {
	const acc = {};
	addFlatObject(acc, object, prefix);
	return acc;
}
