/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { ErrorInfo } from 'react';

import { captureException } from '@sentry/core';
import type { ScopeContext } from '@sentry/types';

// copied from https://github.com/getsentry/sentry-javascript/blob/66bf03bc91f28cb072e5893ea059879c07a835c5/packages/react/src/error.ts#L45
// as @sentry does not expose this function
// and we want to be able to use it in without going through their intermediate functions
// it also merges the contexts from the hint

export function captureReactException(
	error: any,
	{ componentStack }: ErrorInfo,
	hint?: { captureContext: Partial<ScopeContext> },
): string {
	// If on React version >= 17, create stack trace from componentStack param and links
	// to to the original error using `error.cause` otherwise relies on error param for stacktrace.
	// Linking errors requires the `LinkedErrors` integration be enabled.
	// See: https://reactjs.org/blog/2020/08/10/react-v17-rc.html#native-component-stacks
	//
	// Although `componentDidCatch` is typed to accept an `Error` object, it can also be invoked
	// with non-error objects. This is why we need to check if the error is an error-like object.
	// See: https://github.com/getsentry/sentry-javascript/issues/6167
	if (/* isAtLeastReact17(version) && */ isError(error) && componentStack) {
		const errorBoundaryError = new Error(error.message);
		errorBoundaryError.name = `React ErrorBoundary ${error.name}`;
		errorBoundaryError.stack = componentStack;

		// Using the `LinkedErrors` integration to link the errors together.
		setCause(error as any, errorBoundaryError);
	}

	return captureException(error, {
		...hint,
		captureContext: {
			...hint?.captureContext,

			contexts: {
				...hint?.captureContext?.contexts,
				react: { componentStack },
			},
		},
	});
}

function setCause(error: Error & { cause?: Error }, cause: Error): void {
	const seenErrors = new WeakSet();

	function recurse(error: Error & { cause?: Error }, cause: Error): void {
		// If we've already seen the error, there is a recursive loop somewhere in the error's
		// cause chain. Let's just bail out then to prevent a stack overflow.
		if (seenErrors.has(error)) {
			return;
		}
		if (error.cause) {
			seenErrors.add(error);
			return recurse(error.cause as any, cause);
		}
		error.cause = cause;
	}

	recurse(error, cause);
}

const objectToString = Object.prototype.toString;

function isError(wat: any): wat is Error {
	switch (objectToString.call(wat)) {
		case '[object Error]':
		case '[object Exception]':
		case '[object DOMException]':
			return true;
		default:
			return isInstanceOf(wat, Error);
	}
}

function isInstanceOf(wat: any, base: any): boolean {
	try {
		return wat instanceof base;
	} catch (_e) {
		return false;
	}
}
