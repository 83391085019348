/* eslint-disable max-classes-per-file */
import type { ServerError } from '@apollo/client';
import type { DocumentNode, GraphQLFormattedError } from 'graphql';
import uniq from 'lodash/uniq';
import { CustomError } from 'ts-custom-error';

import {
	getPerimeterXEnforcerChallengeWithBlockedUrl,
	type PerimeterXEnforcerChallenge,
	type PerimeterXEnforcerChallengeWithBlockedUrl,
} from '@change-corgi/core/perimeterx';

function combineMessageAndErrors(message: string, errors: readonly GraphQLFormattedError[]): string {
	const errorMessages = uniq(
		errors
			.map((error) => error.message)
			.filter((msg) => msg)
			// "GQL Response Error: Not Found: oups + Forbidden" would be confusing
			.map((msg) => msg.split(':')[0])
			.sort(),
	);
	if (!errorMessages.length) return message;
	return `${message}: ${errorMessages.join(' + ')}`;
}

/**
 * Error thrown when there are errors in the response body
 */
export class GQLResponseError extends CustomError {
	/**
	 * errors found in the response body
	 */
	readonly errors: readonly GraphQLFormattedError[];
	// FIXME operation name is currently not available in the gql server error object
	/**
	 * name of the GQL operation that caused the error
	 */
	readonly operationName: string | undefined;
	/**
	 * requestId coming from the response headers
	 */
	readonly requestId: string | undefined;

	constructor(
		{ message, errors, requestId }: { message?: string; errors: readonly GraphQLFormattedError[]; requestId?: string },
		options?: ErrorOptions,
	) {
		super(combineMessageAndErrors(message || 'GQL Response Error', errors), options);
		this.errors = errors;
		this.requestId = requestId;
	}

	// TODO remove this getter (potential breaking change for corgi)
	// eslint-disable-next-line class-methods-use-this
	get status(): number | undefined {
		return undefined;
	}
}

/**
 * Error thrown when there is a server error (e.g. HTTP 500)
 */
export class GQLResponseServerError extends GQLResponseError {
	private readonly serverError: ServerError;

	constructor({
		message,
		errors,
		serverError,
		requestId,
	}: {
		message?: string;
		errors: readonly GraphQLFormattedError[];
		serverError: ServerError;
		requestId?: string;
	}) {
		super(
			{ message: message || `GQL Response Server Error (${serverError.statusCode})`, errors, requestId },
			{ cause: serverError },
		);
		this.serverError = serverError;
	}

	// TODO remove this getter (potential breaking change for corgi)
	get status(): number | undefined {
		return this.serverError.statusCode;
	}

	get statusCode(): number {
		return this.serverError.statusCode;
	}

	get result(): unknown {
		return this.serverError.result;
	}

	get response(): unknown {
		return this.serverError.response;
	}
}

/**
 * Error thrown when there is a PerimeterX challenge
 */
export class GQLResponsePerimeterXChallengeError extends GQLResponseServerError {
	readonly pxChallenge: PerimeterXEnforcerChallengeWithBlockedUrl;

	constructor({ serverError, pxChallenge }: { serverError: ServerError; pxChallenge: PerimeterXEnforcerChallenge }) {
		super({ message: 'GQL Response PerimeterX Challenge Error', errors: [], serverError });
		this.pxChallenge = getPerimeterXEnforcerChallengeWithBlockedUrl(pxChallenge, serverError.response.url);
	}
}

export type GQLFetchRequest<VARS extends Readonly<Record<string, unknown>> = Readonly<Record<string, unknown>>> =
	Readonly<{
		query: DocumentNode | string;
		variables?: VARS;
		operationName?: string;
		important?: boolean;
		context?: Readonly<Record<string, unknown>>;
		extensions?: Readonly<Record<string, unknown>>;
		/**
		 * Adds a path to the default uri
		 *
		 * e.g. is default uri is /graphql and path is /foo/bar?baz=42,
		 * resulting uri will be /graphql/foo/bar?baz=42
		 *
		 * This will not allow for batching with other queries using a different resulting uri
		 */
		path?: string;
	}>;
