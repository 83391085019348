import type { ReportableError } from '@change-corgi/core/errorReporter/common';
import {
	amountFromBaseUnits,
	amountToBaseUnits,
	getCurrencySymbol,
	getCurrencySymbolPosition,
	getDecimalSeparator,
	isZeroDecimalCurrency,
	localizeCurrency,
	localizeDate,
	localizeNumber,
	localizeRelativeTime,
} from '@change-corgi/core/intl';
import type { CurrencyOptions, NumberOptions, RelativeTimeOptions } from '@change-corgi/core/intl';

import { MissingReplacementsError, MissingTranslationError } from './shared/errors';
import type {
	DateFormat,
	DateType,
	I18nCountryInfo,
	Replacements,
	TranslationKeyArg,
	Translations,
} from './shared/types';
import { translate, translatePlural, translationExists } from './translate';
import type { UsedTranslationsTracker } from './UsedTranslationsTracker';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ListenerCallback = (eventName: keyof I18nLocalizationInterface, ...args: readonly any[]) => void;
type Listeners = Partial<Record<keyof I18nLocalizationInterface, ListenerCallback[]>>;

type Options = Readonly<{
	locale: string;
	translations: Translations | readonly Translations[];
	translationFallback?: (key: string) => string;
	/**
	 * sorted list of i18n country info
	 */
	countries?: readonly I18nCountryInfo[];
	reportError: (error: ReportableError) => void;
	/**
	 * mostly for forwarding listeners when extending
	 */
	listeners?: Listeners;
	/**
	 * mostly for forwarding tracker when extending
	 */
	tracker?: UsedTranslationsTracker;
}>;

function defaultTranslationFallback(key: string) {
	return key.split('.').pop() as string;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface I18nLocalizationInterface {
	translationExists: (key: TranslationKeyArg) => boolean;
	translate: (key: TranslationKeyArg, replacements?: Replacements) => string;
	translatePlural: (key: TranslationKeyArg, count: number, replacements?: Replacements) => string;
	getCountries: () => readonly I18nCountryInfo[];
	getCountry: (code: string) => I18nCountryInfo | undefined;
	localizeNumber: (value: number, options?: NumberOptions) => string;
	amountToBaseUnits: (amount: number, currency: string) => number;
	amountFromBaseUnits: (amount: number, currency: string) => number;
	getDecimalSeparator: () => string;
	localizeCurrency: (value: number, currency: string, options?: CurrencyOptions) => string;
	getCurrencySymbol: (currency: string) => string;
	getCurrencySymbolPosition: (currency: string) => 'before' | 'after';
	isZeroDecimalCurrency: (currency: string) => boolean;
	localizeDate: (date: string | number | Date, dateType: DateType, dateFormat: DateFormat) => string;
	localizeRelativeTime: (
		from: string | number | Date,
		to: string | number | Date,
		options?: RelativeTimeOptions,
	) => string;
}

/* eslint-disable @typescript-eslint/method-signature-style */
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface I18n extends I18nLocalizationInterface {
	trackUsedTranslations(tracker: UsedTranslationsTracker): this;

	getLocale(): string;

	/**
	 * Can be used to plug into specific methods on this utility
	 *
	 * For instance, this can be used to log or throw an error when trying to localize a date on the server
	 */
	on<E extends keyof I18nLocalizationInterface>(
		eventName: E,
		callback: (eventName: E, ...args: Parameters<I18nLocalizationInterface[E]>) => void,
	): this;
	on(eventNames: ReadonlyArray<keyof I18nLocalizationInterface>, callback: ListenerCallback): this;

	extends({
		additionalTranslations,
	}: Readonly<{ additionalTranslations: Translations | readonly Translations[] }>): I18n;

	get hasTranslations(): boolean;

	/**
	 * @returns whether there are translations in the main (first) dictionary object
	 */
	get hasMainTranslations(): boolean;
}
/* eslint-enable @typescript-eslint/method-signature-style */

class I18nImpl implements I18n {
	private readonly locale: string;
	private readonly translations: readonly Translations[];
	private readonly translationFallback?: (key: string) => string;
	private readonly countries: readonly I18nCountryInfo[];
	private readonly countriesMap: Record<string, I18nCountryInfo>;
	private readonly reportError: (error: ReportableError) => void;
	private readonly listeners: Listeners;

	private tracker: UsedTranslationsTracker | undefined;

	constructor({ locale, translations, translationFallback, countries, listeners, tracker, reportError }: Options) {
		this.locale = locale;
		this.translations = Array.isArray(translations)
			? (translations as readonly Translations[])
			: [translations as Translations];
		this.translationFallback = translationFallback;
		this.countries = countries || [];
		this.countriesMap = this.countries.reduce<Record<string, I18nCountryInfo>>((acc, info) => {
			// eslint-disable-next-line no-param-reassign
			acc[info.code] = info;
			return acc;
		}, {});
		this.listeners = { ...(listeners || {}) };
		this.tracker = tracker;
		this.reportError = reportError;
	}

	private handleTranslateError(e: unknown): string {
		if (e instanceof MissingTranslationError) {
			this.reportError({
				error: `i18n: missing translation for ${e.key}`,
				params: { key: e.key, locale: this.locale },
			});
			const value = (this.translationFallback || defaultTranslationFallback)(e.key);
			// not sure if it's the best place for doing this,
			// but we need to also track the translation for non-found keys
			// and moving this logic to the translate function seems like adding a lot of complexity
			this.tracker?.add(e.key, value);
			return value;
		}
		if (e instanceof MissingReplacementsError) {
			this.reportError({
				error: `i18n: missing replacement values for ${e.key}`,
				params: {
					key: e.key,
					translation: e.translation,
					missingReplacements: e.missingReplacements,
					locale: this.locale,
				},
			});
			return e.finalTranslation;
		}
		// other errors will be reported elsewhere
		throw e;
	}

	get hasTranslations(): boolean {
		return this.translations.some((translations) => !!Object.keys(translations).length);
	}

	/**
	 * @returns whether there are translations in the main (first) dictionary object
	 */
	get hasMainTranslations(): boolean {
		return !!Object.keys(this.translations[0] || {}).length;
	}

	translationExists(key: TranslationKeyArg): boolean {
		this.trigger('translationExists', key);
		return translationExists(this.translations, key);
	}

	translate(key: TranslationKeyArg, replacements?: Replacements): string {
		this.trigger('translate', key, replacements);
		try {
			return translate({ translations: this.translations, tracker: this.tracker }, key, replacements);
		} catch (e) {
			return this.handleTranslateError(e);
		}
	}

	translatePlural(key: TranslationKeyArg, count: number, replacements?: Replacements): string {
		this.trigger('translatePlural', key, count, replacements);
		try {
			return translatePlural(
				{ translations: this.translations, locale: this.locale, tracker: this.tracker },
				key,
				count,
				replacements,
			);
		} catch (e) {
			return this.handleTranslateError(e);
		}
	}

	getLocale(): string {
		return this.locale;
	}

	getCountries(): readonly I18nCountryInfo[] {
		this.trigger('getCountries');
		return this.countries;
	}

	getCountry(code: string): I18nCountryInfo | undefined {
		this.trigger('getCountry', code);
		return this.countriesMap[code];
	}

	localizeNumber(value: number, options?: NumberOptions): string {
		this.trigger('localizeNumber', value, options);
		return localizeNumber(this.locale, value, options);
	}

	amountToBaseUnits(amount: number, currency: string): number {
		this.trigger('amountToBaseUnits', amount, currency);
		return amountToBaseUnits(amount, currency);
	}

	amountFromBaseUnits(amount: number, currency: string): number {
		this.trigger('amountFromBaseUnits', amount, currency);
		return amountFromBaseUnits(amount, currency);
	}

	getDecimalSeparator(): string {
		this.trigger('getDecimalSeparator');
		return getDecimalSeparator(this.locale);
	}

	localizeCurrency(value: number, currency: string, options?: CurrencyOptions): string {
		this.trigger('localizeCurrency', value, currency, options);
		return localizeCurrency(this.locale, value, currency, options);
	}

	getCurrencySymbol(currency: string): string {
		this.trigger('getCurrencySymbol', currency);
		return getCurrencySymbol(this.locale, currency);
	}

	getCurrencySymbolPosition(currency: string): 'before' | 'after' {
		this.trigger('getCurrencySymbolPosition', currency);
		return getCurrencySymbolPosition(this.locale, currency);
	}

	isZeroDecimalCurrency(currency: string): boolean {
		this.trigger('isZeroDecimalCurrency', currency);
		return isZeroDecimalCurrency(currency);
	}

	localizeDate(date: string | number | Date, dateType: DateType, dateFormat: DateFormat): string {
		this.trigger('localizeDate', date, dateType, dateFormat);
		return localizeDate(this.locale, date, dateType, dateFormat);
	}

	localizeRelativeTime(
		from: string | number | Date,
		to: string | number | Date,
		options?: RelativeTimeOptions,
	): string {
		this.trigger('localizeRelativeTime', from, to, options);
		return localizeRelativeTime(this.locale, from, to, options);
	}

	/**
	 * Can be used to track the translations that are used by the app,
	 * for instance during SSR so we can use those during hydration
	 *
	 * @example
	 * const usedTranslationsTracker = createUsedTranslationsTracker();
	 * i18n.trackUsedTranslations(usedTranslationsTracker);
	 * // render...
	 * HYDRATION_DATA = {
	 *   // ...
	 *   translations: usedTranslationsTracker.getUsedTranslations()
	 * }
	 */
	trackUsedTranslations(tracker: UsedTranslationsTracker): this {
		this.tracker = tracker;
		return this;
	}

	/**
	 * Can be used to plug into specific methods on this utility
	 *
	 * For instance, this can be used to log or throw an error when trying to localize a date on the server
	 */
	on<E extends keyof I18nLocalizationInterface>(
		eventName: E,
		callback: (eventName: E, ...args: Parameters<I18nLocalizationInterface[E]>) => void,
	): this;

	on(eventNames: ReadonlyArray<keyof I18nLocalizationInterface>, callback: ListenerCallback): this;
	on(
		eventNames: keyof I18nLocalizationInterface | ReadonlyArray<keyof I18nLocalizationInterface>,
		callback: ListenerCallback,
	): this {
		(Array.isArray(eventNames) ? eventNames : [eventNames]).forEach((eventName: keyof I18nLocalizationInterface) => {
			const listeners = this.listeners[eventName] || ([] as ListenerCallback[]);
			this.listeners[eventName] = listeners;
			listeners.push(callback);
		});
		return this;
	}

	private trigger<E extends keyof I18nLocalizationInterface>(
		eventName: E,
		...args: Parameters<I18nLocalizationInterface[E]>
	) {
		const listeners = this.listeners[eventName];
		if (!listeners) return;
		listeners.forEach((callback) => {
			callback(eventName, ...args);
		});
	}

	extends({
		additionalTranslations,
	}: Readonly<{ additionalTranslations: Translations | readonly Translations[] }>): I18n {
		const additionalTranslationsArray = Array.isArray(additionalTranslations)
			? (additionalTranslations as readonly Translations[])
			: [additionalTranslations as Translations];
		const translations = [...this.translations, ...additionalTranslationsArray];

		return new I18nImpl({
			locale: this.locale,
			translations,
			translationFallback: this.translationFallback,
			countries: this.countries,
			reportError: this.reportError,
			listeners: this.listeners,
			tracker: this.tracker,
		});
	}
}

export type { I18n, I18nLocalizationInterface };

export function createI18n(options: Options): I18n {
	return new I18nImpl(options);
}

export function createI18nFake(errorMsg: string): I18n {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		localizeDate: errorFn,
		localizeRelativeTime: errorFn,
		localizeNumber: errorFn,
		translate: errorFn,
		translatePlural: errorFn,
		translationExists: errorFn,
		localizeCurrency: errorFn,
		getDecimalSeparator: errorFn,
		amountFromBaseUnits: errorFn,
		amountToBaseUnits: errorFn,
		getCurrencySymbol: errorFn,
		getCurrencySymbolPosition: errorFn,
		isZeroDecimalCurrency: errorFn,
		getLocale: errorFn,
		getCountries: errorFn,
		getCountry: errorFn,
		trackUsedTranslations: errorFn,
		on: errorFn,
		extends: errorFn,
		get hasTranslations() {
			return errorFn();
		},
		get hasMainTranslations() {
			return errorFn();
		},
	};
}
