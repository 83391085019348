/* eslint-disable promise/avoid-new */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { getWindow } from '@change-corgi/core/window';

import { getOptimizelyInstance } from './script';

export function isReady(): boolean {
	const optimizely = getOptimizelyInstance();
	if (!optimizely || !optimizely.get) return false;
	return !!optimizely.get('state').getActivationId();
}

export async function waitForState(stateCheck: () => boolean, timeout: number): Promise<void> {
	if (stateCheck()) {
		return;
	}

	await Promise.race([
		new Promise((resolve) => {
			const fn = () => {
				setTimeout(() => {
					if (stateCheck()) {
						resolve(undefined);
						return;
					}
					fn();
				}, 100);
			};
			fn();
		}),

		new Promise((_resolve, reject) => {
			setTimeout(() => {
				reject(new Error('Timed out'));
			}, timeout);
		}),
	]);
}

export async function waitForReady(timeout: number): Promise<void> {
	const win: any = getWindow();
	const optimizely = getOptimizelyInstance();

	if (isReady()) {
		delete win.optimizelyTimeout;
		return Promise.resolve();
	}

	if (win.optimizelyTimeout) return Promise.reject(new Error('Timed out'));

	await Promise.race([
		new Promise((resolve) => {
			optimizely.push({
				type: 'addListener',
				filter: {
					type: 'lifecycle',
					name: 'activated',
				},
				handler: () => {
					delete win.optimizelyTimeout;
					resolve(undefined);
				},
			});
		}),

		new Promise((_resolve, reject) => {
			setTimeout(() => {
				win.optimizelyTimeout = true;
				reject(new Error('Timed out')); // timed out
			}, timeout);
		}),
	]);

	return Promise.resolve();
}
