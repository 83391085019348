import { useEffect } from 'react';
import type { JSX } from 'react';

import { useHref } from 'react-router';
import type { NavigateProps as BaseNavigateProps } from 'react-router-dom';
import { Navigate as BaseNavigate } from 'react-router-dom';

import { isExternalHttpUrl } from '@change-corgi/core/navigation';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useNavigateExternal } from '../hooks/useNavigateExternal';
import { useDisabledRenderedNavigation } from '../renderedNavigationContext/hook';
import { addReason } from '../shared/addReason';
import { useStaticRouterContext } from '../staticContext/hook';

type ExternalProps = Readonly<{
	externalUrl: string;
}>;

function ExternalNavigate({ externalUrl, replace, state }: BaseNavigateProps & ExternalProps): JSX.Element | null {
	const navigateExternal = useNavigateExternal();

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		navigateExternal(externalUrl, { replace: !!replace, state });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // we shouldn't navigate more than once
	return null;
}

export type NavigateProps = BaseNavigateProps &
	Readonly<{
		reason?: string;
		permanent?: boolean;
		/**
		 * forces the navigation to be external/internal, ignoring the list of internal routes from the utility context
		 */
		forceMode?: 'internal' | 'external';
	}>;

/**
 * Wrapper of react-router's Navigate to handle external navigation
 */
// eslint-disable-next-line max-statements
export function Navigate({ reason, permanent, forceMode, ...props }: NavigateProps): JSX.Element | null {
	const { to: initialTo, ...restProps } = props;

	const resolvedHref = useHref(initialTo);
	const { errorReporter, navigation } = useUtilityContext();
	const href = typeof initialTo === 'string' && isExternalHttpUrl(initialTo) ? initialTo : resolvedHref;
	const staticRouterContext = useStaticRouterContext();
	const disabledRenderedNavigation = useDisabledRenderedNavigation();

	if (disabledRenderedNavigation) return null;

	try {
		const to = addReason(href, reason);

		// this reproduces the logic of staticContext from react-router v5 (which was removed in v6)
		if (staticRouterContext) {
			staticRouterContext.redirectLocation = to;
			staticRouterContext.permanent = !!permanent;
			// we should not actually redirect in a static router
			// allowRedirect is mostly for tests using the StaticRouterContext
			if (!staticRouterContext.allowRedirect) return null;
		}

		// mostly useful for tests using the StaticRouterContext
		if (!staticRouterContext?.forceInternalRouting) {
			const externalUrl = navigation.getExternalUrl(to, { forceMode });

			if (externalUrl) {
				return <ExternalNavigate externalUrl={externalUrl} {...props} />;
			}
		}

		return <BaseNavigate to={to} {...restProps} />;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		void errorReporter.report({ error: e, params: { target: initialTo, component: 'Navigate' } });

		return null;
	}
}
