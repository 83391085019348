import type { JSX, PropsWithChildren } from 'react';
import { createContext as createContextBase, useContext as useContextBase, useMemo } from 'react';

import type { Options, OptionsAdvanced, Result } from './types';

export function createContext<T, PROVIDER_PROPS extends Record<string, unknown> = { value: T }>(
	defaultValue: T,
	{ name, processProviderProps }: PROVIDER_PROPS extends { value: T } ? Options<T> : OptionsAdvanced<T, PROVIDER_PROPS>,
): Result<T, PROVIDER_PROPS> {
	const Context = createContextBase(defaultValue);

	const Provider = function ContextProvider({ children, ...props }: PropsWithChildren<PROVIDER_PROPS>): JSX.Element {
		const value = useMemo(
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
			() => (processProviderProps ? processProviderProps(props as any) : props.value),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			Object.values(props),
		);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
		return <Context.Provider value={value as any}>{children}</Context.Provider>;
	};
	Provider.displayName = `${name}Provider`;

	const useContext = function useContext() {
		return useContextBase(Context);
	};

	return { Context, Provider, useContext };
}
