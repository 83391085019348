import type { JSX, MouseEvent } from 'react';
import { forwardRef, useCallback, useMemo } from 'react';

import omit from 'lodash/fp/omit';
import { useHref } from 'react-router';
import type { NavLinkProps as BaseNavLinkProps } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { NavLink as BaseNavLink } from 'react-router-dom';

import { isExternalHttpUrl } from '@change-corgi/core/navigation';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useNavigateExternal } from '../hooks/useNavigateExternal';
import { shouldNavigate } from '../shared/link-click';
import { getLinkRel } from '../shared/rel';

// remove non-html attributes from LinkProps and RouteComponentProps
const pickExternalLinkProps = omit(['theme', 'location', 'match', 'replace', 'state', 'href', 'children', 'to']);

type ExternalProps = Readonly<{
	externalUrl?: string;
}>;

const ExternalNavLink = forwardRef<HTMLAnchorElement, BaseNavLinkProps & ExternalProps>(function ExternalNavLink(
	{
		externalUrl,
		onClick: onClickInitial,
		className: classNameInitial,
		style: styleInitial,
		...props
	}: BaseNavLinkProps & ExternalProps,
	ref,
): JSX.Element | null {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { replace, state, children, ...rest } = props;
	const navigateExternal = useNavigateExternal();

	const onClick = useCallback(
		(event: MouseEvent<HTMLAnchorElement>) => {
			onClickInitial?.(event);
			if (externalUrl && shouldNavigate(event)) {
				event.preventDefault();
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				navigateExternal(externalUrl, { replace: !!replace, state });
			}
		},
		[onClickInitial, navigateExternal, externalUrl, replace, state],
	);
	const className = useMemo(() => {
		if (typeof classNameInitial === 'function') {
			return classNameInitial({ isActive: false, isPending: false, isTransitioning: false });
		}
		return classNameInitial;
	}, [classNameInitial]);
	const style = useMemo(() => {
		if (typeof styleInitial === 'function') {
			return styleInitial({ isActive: false, isPending: false, isTransitioning: false });
		}
		return styleInitial;
	}, [styleInitial]);
	return (
		<a
			href={externalUrl}
			onClick={onClick}
			className={className}
			style={style}
			ref={ref}
			{...pickExternalLinkProps(rest)}
		>
			{typeof children === 'function'
				? children({ isActive: false, isPending: false, isTransitioning: false })
				: children}
		</a>
	);
});

export type NavLinkProps = BaseNavLinkProps &
	Readonly<{
		/**
		 * forces the navigation to be external/internal, ignoring the list of internal routes from the utility context
		 */
		forceMode?: 'internal' | 'external';
	}>;

/**
 * Wrapper of react-router's NavLink to handle external navigation
 */
export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(function NavLink(
	{ forceMode, ...props },
	ref,
): JSX.Element | null {
	const { to } = props;

	const resolvedHref = useHref(to);
	const { errorReporter, navigation } = useUtilityContext();
	const href = typeof to === 'string' && isExternalHttpUrl(to) ? to : resolvedHref;

	try {
		const externalUrl = navigation.getExternalUrl(href, { forceMode });

		const { children, ...rest } = props;

		if (externalUrl) {
			return (
				<ExternalNavLink externalUrl={externalUrl} rel={getLinkRel(props)} ref={ref} {...rest}>
					{children}
				</ExternalNavLink>
			);
		}

		return (
			<BaseNavLink rel={getLinkRel(props)} ref={ref} {...rest}>
				{children}
			</BaseNavLink>
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		const { children, ...rest } = props;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		void errorReporter.report({ error: e, params: { target: to, component: 'NavLink' } });

		return (
			<ExternalNavLink rel={getLinkRel(props)} ref={ref} {...rest}>
				{children}
			</ExternalNavLink>
		);
	}
});
