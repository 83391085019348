/* eslint-disable max-classes-per-file */
import { CustomError } from 'ts-custom-error';

export class InvalidFcmError extends CustomError {
	readonly fcmName: string;
	readonly reason?: string;

	constructor({ fcmName, reason }: { fcmName: string; reason?: string }) {
		super(`Invalid FCM value for ${fcmName}`);
		this.fcmName = fcmName;
		this.reason = reason;
	}
}

export class FcmMergeError extends CustomError {
	readonly fcmName: string;
	readonly reason?: string;

	constructor({ fcmName, reason }: { fcmName: string; reason?: string }) {
		super(`FCM merge failure for ${fcmName}`);
		this.fcmName = fcmName;
		this.reason = reason;
	}
}
