import { createContext } from './createContext';
import type { Options, OptionsAdvanced, ResultMandatory } from './types';

function createMandatoryContext<T, PROVIDER_PROPS extends Record<string, unknown> = { value: T }>(
	defaultValue: T | undefined,
	options: PROVIDER_PROPS extends { value: T } ? Options<T> : OptionsAdvanced<T, PROVIDER_PROPS>,
): ResultMandatory<T, PROVIDER_PROPS>;
/* eslint-disable @typescript-eslint/no-explicit-any */
function createMandatoryContext(
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	defaultValue: any,

	options: OptionsAdvanced<any, any> | Options<any>,
): ResultMandatory<any, any> {
	const { Context, Provider, useContext } = createContext(defaultValue, options);

	const useContextMandatory = function useContextMandatory() {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const context = useContext();

		if (context === null || context === undefined) {
			throw new Error(`${options.name} was not set`);
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return context;
	};

	return { Context, Provider, useContext: useContextMandatory };
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export { createMandatoryContext };
