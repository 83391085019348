import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import { createErrorReporter as createErrorReporterBase } from '@change-corgi/core/errorReporter/common';

import { createSentryScope } from './sentry';

type Options = Omit<Parameters<typeof createErrorReporterBase>[0], 'createNotifier' | 'createSentryScope' | 'ssr'>;

export function createErrorReporter(options: Options): ErrorReporter {
	return createErrorReporterBase({
		...options,
		createSentryScope,
	});
}
