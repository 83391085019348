import type { GqlClient } from '@change-corgi/core/gql';

import { treatExperiment } from './api';
import type { IExperimentOverrides } from './overrides';
import type { TreatedStore } from './TreatedStore';
import type { Experiment, TreatableExperiment } from './types';
import { createDummyExperiment, createTreatableExperiment } from './types';

export function getTreatableExperiments(
	experiments: readonly Experiment[] | undefined,
	{
		treatedStore,
		overrides,
		gqlFetch,
	}: {
		treatedStore: TreatedStore;
		overrides: IExperimentOverrides;
		gqlFetch: GqlClient['fetch'];
	},
): readonly TreatableExperiment[] | undefined {
	const overrideNames = Object.keys(overrides.all());

	if (!experiments) {
		// create dummy experiments for experiments that were found in overrides
		return overrideNames.map((id) => createDummyExperiment(id, overrides.get(id)));
	}

	const realExperiments = experiments.map((experiment) => {
		const treatableExperiment = createTreatableExperiment(experiment, overrides.get(experiment.id), treatedStore);
		return {
			id: treatableExperiment.id,
			variation: treatableExperiment.variation,
			overridden: treatableExperiment.overridden,
			originalExperiment: treatableExperiment.originalExperiment,
			// treatableExperiment.treated is a getter, so we cannot rely on the spread operator
			get treated() {
				return treatableExperiment.treated;
			},
			async treat() {
				if (this.treated) {
					return undefined;
				}

				// do not "await" here as we want to call treatedStore.setTreated right away
				const promise = treatExperiment(this.id, { gqlFetch });

				// make sure that event is marked as treated when using cached getExperimentsFromService()
				treatedStore.setTreated(experiment.id);

				return promise;
			},
		};
	});

	// create dummy experiments for experiments that were found in overrides but not in list from server
	const dummyExperiments = overrideNames
		.filter((name) => !realExperiments.find(({ id }) => id === name))
		.map((id) => createDummyExperiment(id, overrides.get(id)));

	return [...realExperiments, ...dummyExperiments];
}
